@media all and (min-width: 480px) {
  .garmin {
    margin: auto;
    width: 50%;
    text-align: justified;
    padding: 30px 0;
  }
  .garminButton {
    margin: auto;
    text-align: center;
    padding: 30px 0;
  }
}
